<template>
  <div id="userOrder">
    <div class="EmployeesAccountTitle flexAndCenter">
      <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("收藏店铺") }}</div>
    </div>
    <section>
      <div class="shopHeader">
        <div class="shopHeaderLeft">
          <div class="label">{{ $fanyi("标签名称") }}:</div>
          <div class="tagLists">
            <div v-for="(item,index) in tagLists" :key="index"
                 :class="form.tag_ids.lastIndexOf(item.id)!==-1?'active':''"
                 class="tagContainer" @click="updateTagIndex(item)">
              <span v-if="tagInfo.id!==item.id">{{ item.tag_name }}</span>
              <el-input v-else v-model="item.tag_name" size="mini" style="width: 100px"
                        @blur.stop="updateTagName(item)"/>
              <img src="../../../../assets/redEditIcon.png" style="margin-left: 8px"
                   @click.stop="getEditTagInfo(item)"/>
              <div class="deleteTagContainer" @click.stop="deleteIcon(item)">x</div>
            </div>
          </div>
        </div>
        <div class="shopHeaderRight">
          <div class="keywords flexAndCenter">
            <div class="fontSize14 color333">{{ $fanyi("店铺名称") }}：</div>
            <el-input v-model="form.keywords" style="width: 200px" :placeholder="$fanyi('请输入店铺名称')" size="mini"/>
          </div>
          <div class="btnList flexAndCenter">
            <div @click="getShopsList">{{ $fanyi("筛选") }}</div>
            <div @click="form.keywords='';getShopsList()">{{ $fanyi("重置") }}</div>
          </div>
        </div>
      </div>
      <div class="shops" v-if="shopData.length != 0" v-loading="loading">
        <div
            class="shopsOpt"
            v-for="(shopDataitem, shopDataindex) in shopData"
            :key="shopDataindex"
        >
          <div class="optLeft">
            <h1
                class="goodsTitleBox"
                @click="shopGoodsPageJump(shopDataitem)"
                :title="shopDataitem.shop_name"
            >
              {{ shopDataitem.shop_name }}
            </h1>
            <el-input v-model="shopDataitem.note" size="mini" @change="changeNote(shopDataitem.id, shopDataitem.note)"/>
            <div class="tagBtnList">
              <el-popover trigger="hover" placement="bottom" @hide="shopTagsMouseleave(shopDataitem)"
                          popper-class="shopTagPopover">
                <div>
                  <div class="fontSize14 color333" style="margin-bottom: 16px">标签选择：</div>
                  <div>
                    <div v-for="(tagItem,tagIndex) in tagLists" :key="tagIndex" class="flexAndCenter tagItem">
                      <input type="checkbox" v-model="tagItem.checked"/>
                      <div :title="tagItem.tag_name">{{ tagItem.tag_name }}</div>
                    </div>
                  </div>
                  <el-input :placeholder="$fanyi('请输入标签名')" v-model="addTagTxt" class="input-with-select">
                    <el-button slot="append" @click="addTag">{{ $fanyi('新增') }}</el-button>
                  </el-input>
                </div>
                <div class="btnLeft" slot="reference" @mouseenter="shopTagOpen(shopDataitem)">{{ $fanyi("标签") }}</div>
              </el-popover>
              <div class="btnRight" @click="delshop(shopDataindex,shopDataitem)">
                <img src="../../../../assets/SearchResultsPage/Collected.png" alt="">
                <div>{{ $fanyi("取消收藏") }}</div>
              </div>
            </div>
          </div>
          <div class="optRight" v-if="shopDataitem.goods.length>0">
            <div
                class="carouselBtn"
                @click="shopCarouselChange('left', shopDataindex)"
            >
              <img src="../../../../assets/favoriteShop/jaintouzuo.png" alt=""/>
            </div>

            <el-carousel
                ref="shopCarousel"
                trigger="click"
                width="100px"
                height="100px"
                :autoplay="false"
                arrow="never"
                indicator-position="none"
            >
              <el-carousel-item
                  v-for="(goodsItem, goodsIndex) in shopDataitem.goods"
                  :key="goodsIndex"
              >
                <div class="optRightCon">
                  <div
                      class="shopGoods"
                      v-for="(iu, sndex) in goodsItem"
                      :key="sndex"
                      @click="$fun.toCommodityDetails(iu.goodsId,iu.shopType)"
                  >
                    <el-image :src="iu.imgUrl" v-if="iu!=null">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div
                class="carouselBtn"
                @click="shopCarouselChange('right', shopDataindex)"
            >
              <img src="../../../../assets/favoriteShop/jaintouyou.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div class="allpag">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-size="form.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagLists: [], //标签列表
      shopData: [], //收藏店铺列表
      addTagTxt: "", //添加标签的文本
      loading: true,
      total: 0,
      form: {
        keywords: '',
        tag_ids: '',
      },
      tagInfo: {},
    };
  },
  created() {
    this.getTagData();
    this.form.pageSize = 5;
    this.getShopsList("one");
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    // 添加标签
    addTag(item) {
      if (!this.addTagTxt) return false;
      this.$api.favoriteTagAdd({
        type: 2,
        tag_name: this.addTagTxt,
      }).then((res) => {
        if (res.code != 0) return;
        this.addTagTxt = "";
        // 重新获取标签列表
        this.$api.favoriteTagList({type: "2"}).then((res) => {
          this.tagLists = res.data;
          // 调用处理标签列表事件
          this.tagDataProcessing();
          this.shopTagOpen(item);
          this.$forceUpdate();
        });
      });
    },
    // 全局鼠标点击事件
    bodyCloseMenus() {
      // 关闭所有商品的标签弹窗
      this.shopData.forEach((item) => {
        item.showTagWin = false;
      });
      this.$forceUpdate();
    },
    //删除icon
    deleteIcon(item) {
      this.$confirm(
          '削除の確認',
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.favoriteTagDelete({
          tag_id: item.id
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.getTagData();
          this.form.pageSize = 5;
          this.getShopsList();
        })
      })
    },
    //获取要编辑的tag信息
    getEditTagInfo(val) {
      this.tagInfo = this.$fun.deepClone(val);
      this.$forceUpdate();
    },
    // 获取商品收藏夹标签列表并调用商品收藏夹列表获取函数
    getTagData() {
      this.$api.favoriteTagList({type: "2"}).then((res) => {
        res.data.forEach((item) => {
          item.id = item.id + ''
        })
        this.tagLists = res.data;
        // 调用处理标签列表事件
        this.tagDataProcessing();
        // 调用获取商品收藏夹列表事件
      });
    },
    //获取要筛选的标签
    updateTagIndex(val) {
      if (this.form.tag_ids === '') {
        this.form.tag_ids = val.id
      } else if (this.form.tag_ids !== '' && this.form.tag_ids.indexOf(val.id) === -1) {
        this.form.tag_ids += ',' + val.id
      } else if (this.form.tag_ids !== '' && this.form.tag_ids.indexOf(val.id) !== -1) {
        let index = this.form.tag_ids.indexOf(val.id);
        let target = '';
        if (index === 0) {
          target = val.id;
        } else {
          target = ',' + val.id;
        }
        this.form.tag_ids = this.form.tag_ids.replace(target, "");
      }
      this.getShopsList();
    },
    // 处理收藏夹标签数据
    tagDataProcessing() {
      this.tagLists.forEach((item) => {
        item.checked = false;
        item.titleChecked = false;
      });
    },
    // 修改店铺备注
    changeNote(id, note) {
      this.$api.shopNoteSave({
        id: id,
        note: note,
      }).then(() => {
      });
    },
    // 分页事件
    handleSizeChange(val) {
      this.form.page_num = val;
      this.form.page = 1;
      this.getShopsList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getShopsList();
    },
    // 滑动店铺商品列表
    shopCarouselChange(type, index) {
      if (type == "left") {
        this.$refs.shopCarousel[index].prev();
      } else if (type == "right") {
        this.$refs.shopCarousel[index].next();
      }
    },
    //更新标签的名称
    updateTagName(val) {
      if (val.tag_name !== '') {
        this.$api.tagNameEdit({
          tag_id: val.id,
          tag_name: val.tag_name
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.form.tag_ids = '';
          this.getTagData();
          this.form.pageSize = 5;
          this.tagInfo = {};
          this.form.page = 1;
          this.getShopsList();
        }).catch(() => {
        });
      }
    },
    // 获取店铺列表
    getShopsList() {
      this.loading = true;
      let datas = {
        tag_ids: this.form.tag_ids,
        page: this.form.page,
        pageSize: this.form.pageSize,
        keywords: this.form.keywords,
      };
      this.$api.shopList(datas).then((res) => {
        this.loading = false;
        this.shopData = res.data.data;
        this.total = res.data.total;
        this.ProcessingShopData();
      })
      this.ProcessingShopData();
    },
    //处理店铺数据
    ProcessingShopData() {
      this.shopData.forEach((item) => {
        let lack = 4 - (item.goods.length % 4); //取得店铺商品数离4个的倍数差多少个商品的数量
        //差多少个就从最前面取多少个填充到最后
        for (let i = 0; i < lack; i++) {
          item.goods.push(item.goods[i]);
        }
        //  把单个店铺的商品分成四个一组
        item.goods = this.$fun.changeArr(item.goods, 4);
        item.showTagWin = false;
      });
      this.$forceUpdate();
    },
    // 打开分配店铺标签窗口
    shopTagOpen(item) {
      this.shopData.forEach((item) => {
        item.showTagWin = false;
      });
      let shopsTagIds = [];
      if (item.tag_ids != undefined && item.tag_ids != '' && item.tag_ids.indexOf(",") != -1) {
        shopsTagIds = item.tag_ids.split(",");
      }
      // 初始化商品标签列表数据
      this.tagLists.forEach((item) => {
        shopsTagIds.forEach((items) => {
          if (item.id == items) {
            item.checked = true;
          }
        })
      });
      item.showTagWin = true;
      this.$forceUpdate();
    },
    shopTagsMouseleave(item) {
      let tagIds = '';
      this.tagLists.forEach((tagItem) => {
        if (tagItem.checked === true) {
          tagIds += tagItem.id + ','
        }
      })
      tagIds = tagIds.substr(0, tagIds.length - 1);
      if (tagIds !== '') {
        this.$api.shopToTags({
          id: item.id,
          tag_ids: tagIds
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        })
      }
    },
    // 删除店铺
    delshop(index, item) {
      this.$confirm(
          this.$fanyi('是否取消收藏此店铺？'),
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.shopDelete({
          shop_type: item.shop_type,
          shop_name: [item.shop_name]
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.getShopsList();
          this.getTagData();
        })
      }).catch(() => {

      });
    },
    shopGoodsPageJump(val) {
      let href = `/shopGoods?shopId=${val.shop_id}&shopName=${val.shop_name}&shopType=${val.shop_type}`;
      window.open(href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  section {
    background: #fff;
    padding: 14px 26px 0;

    .shops {
      width: 100%;
      min-height: 300px;

      .shopsOpt {
        width: 100%;
        height: 202px;
        border-radius: 10px;
        border: 1px solid #DFDFDF;
        padding: 30px 20px 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .optLeft {
          width: 396px;
          height: 100%;
          position: relative;

          .goodsTitleBox {
            font-weight: bold;
            color: #333333;
            font-size: 18px;
            margin-bottom: 20px;
            cursor: pointer;
            width: 396px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

          }

          /deep/ .el-input__inner {
            width: 274px;
            height: 26px;
            margin-bottom: 20px;
          }

          .tagBtnList {
            display: flex;
            align-items: center;

            .btnLeft {
              width: 90px;
              height: 39px;
              background: #B4272B;
              border-radius: 8px;
              text-align: center;
              line-height: 39px;
              cursor: pointer;
              color: #FFFFFF;
              font-size: 14px;
              margin-right: 20px;
            }

            .btnRight {
              display: flex;
              align-items: center;
              padding: 9px 17px 9px 19px;
              border-radius: 8px;
              border: 1px solid #B4272B;
              cursor: pointer;

              img {
                width: 17px;
                height: 16px;
                margin-right: 10px;
              }

              div {
                color: #B4272B;
                font-size: 14px;
              }
            }

            /deep/ .input-with-select {

            }
          }

          .btns {
            img {
              width: 24px;
              height: 24px;
              cursor: pointer;

              &:first-child {
                margin-right: 20px;
              }
            }
          }

          .pop-up {
            position: absolute;
            top: 100% px;
            left: 0px;
            width: 246px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
            border-radius: 10px;
            padding: 20px;
            z-index: 2;
            align-items: center;

            p {
              display: flex;
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 20px;
              }

              .che {
                width: 16px;
                height: 16px;
                background-color: #ffffff;
                border: solid 1px #dddddd;
                -webkit-border-radius: 3px;
                border-radius: 3px;
                font-size: 0.8rem;
                margin: 0;
                margin-right: 6px;
                padding: 0;
                position: relative;
                display: inline-block;
                vertical-align: top;
                cursor: default;
                -webkit-appearance: none;
                -webkit-user-select: none;
                user-select: none;
                -webkit-transition: background-color ease 0.1s;
                transition: background-color ease 0.1s;

                &:checked {
                  background: #1673ff;
                }

                &:checked::after {
                  content: "";
                  top: 1px;
                  left: 1px;
                  position: absolute;
                  background: transparent;
                  border: #fff solid 2px;
                  border-top: none;
                  border-right: none;
                  height: 6px;
                  width: 10px;
                  -moz-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                }
              }

              > span {
                display: block;
                height: 16px;
                max-width: 170px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;

                color: #000000;
                line-height: 16px;
                margin-right: auto;
              }
            }

            .addTagBtn {
              position: relative;
              height: 30px;

              input {
                width: 90%;
                height: 100%;
                background: #ffffff;
                border-radius: 15px;
                border: 1px solid rgba(30, 41, 151, 0.3);
                padding: 0 12px;
                font-size: 14px;
              }

              /deep/ .el-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 30px;
                width: 64px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
              }
            }
          }
        }

        .optRight {
          width: 580px;
          // margin: 20px 30px 0 20px;
          // 轮播图两侧样式
          display: flex;
          justify-content: space-between;
          align-items: center;

          .carouselBtn {
            width: 40px;
            height: 40px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 20px;
            }
          }

          /deep/ .el-carousel__arrow--right,
          /deep/ .el-carousel__arrow--left {
            height: 0;
            overflow: hidden;
          }

          .el-carousel {
            width: 460px;
          }

          /deep/ .el-carousel__arrow {
            background: rgba(255, 255, 255, 0.5);

            i {
              color: $homePageSubjectColor;
            }
          }

          /deep/ .el-carousel__button {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border-radius: 50%;
          }

          /deep/ .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
          .el-carousel__button {
            background-color: transparent;
          }

          .optRightCon {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .shopGoods {
              width: 100px;
              height: 100px;
              cursor: pointer;

              /deep/ .el-image {
                $imgSize: 100px;
                width: $imgSize;
                height: $imgSize;
                transition: 0.3s;
                text-align: center;

                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }
            }
          }
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 25px 0;
      padding-bottom: 30px;
    }

    .shopHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DFDFDF;
      padding-bottom: 17px;
      margin-bottom: 26px;

      .shopHeaderLeft {
        color: #333333;
        font-size: 14px;
        margin-right: 26px;
        display: flex;

        .tagLists {
          margin-left: 26px;
          display: flex;
          flex-wrap: wrap;
          width: 500px;

          .tagContainer {
            padding: 6px 9px;
            color: #333333;
            font-size: 12px;
            background: #F0F0F0;
            border-radius: 6px;
            margin-right: 12px;
            cursor: pointer;
            position: relative;
            margin-bottom: 10px;
          }

          .active {
            background: #fff;
            color: #B4272B;
            border: 1px solid #B4272B;
          }

          .tagContainer {
            position: relative;
            padding: 7px 9px;
            color: #333;
            font-size: 12px;
            border-radius: 6px;
            background: #F0F0F0;
            margin-right: 22px;
            margin-bottom: 12px;
            cursor: pointer;
          }

          .deleteTagContainer {
            width: 12px;
            height: 12px;
            background: #B4272B;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            font-size: 12px;
            line-height: 8px;
            cursor: pointer;
            position: absolute;
            left: -5px;
            top: -5px;
          }

          img {
            position: absolute;
            right: -5px;
            top: -5px;
          }
        }
      }

      .shopHeaderRight {
        display: flex;
        align-items: center;

        .keywords {
          margin-right: 26px;

          /deep/ .el-input__inner {
            width: 200px;
            height: 30px;
          }
        }

        .btnList {
          div {
            width: 76px;
            height: 30px;
            background: #B4272B;
            border-radius: 6px;
            color: #FEFEFE;
            font-size: 12px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #B4272B;
            cursor: pointer;
          }

          div:last-child {
            margin-left: 10px;
            color: #B4272B;
            background: #fff;
          }
        }
      }
    }
  }
}
</style>
